import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Typography from "@material-ui/core/Typography";
import Fade from "react-reveal/Fade";
import styles from "./Album.module.scss";

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
        nodes {
          id
          childImageSharp {
            fluid(quality: 70, maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return data.allFile.nodes.map(node => ({
    ...node.childImageSharp,
    id: node.id,
  }));
};

export default () => {
  const images = useGallery();
  const [imageWidth, setImageWidth] = useState(300);
  const [imageSpan, setImageSpan] = useState(1);

  // Get image width
  useEffect(() => {
    const width = Math.ceil(
      document.querySelector(".imageContainer > div").clientWidth
    );
    setImageWidth(width);

    // Change span value based on mediaquery
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    if (mediaQuery.matches) {
      setImageSpan(2);
    }
    // When resize, reset current width
    const currentWidth = window.innerWidth;
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // Only for Desktop device
        if (currentWidth !== window.innerWidth) {
          const width = Math.ceil(
            document.querySelector(".imageContainer > div").clientWidth
          );
          setImageWidth(width);
        }
        // Change span value based on mediaquery
        const mediaQuery = window.matchMedia("(min-width: 768px)");
        if (mediaQuery.matches) {
          setImageSpan(2);
        }
      }, 500);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.addEventListener("resize", resizeListener);
    };
  }, []);

  const gallery = images.map(({ id, fluid }, i) => {
    // Get current height
    const ratio = fluid.aspectRatio;
    const height = imageWidth / ratio;
    const spans = Math.ceil(height / 10 + imageSpan);

    return (
      <div
        key={id}
        className={styles.album__item}
        style={{ gridRowEnd: `span ${spans}` }}
      >
        <Fade delay={i * 20}>
          <Img key={id} fluid={fluid} />
        </Fade>
      </div>
    );
  });

  return (
    <div className={styles.root}>
      <Typography variant="h1">アルバム</Typography>
      <Typography className={styles.lead}>箕澤屋での思い出たち。</Typography>

      <div className={styles.container}>
        <div className={styles.lead}></div>
        <div className={`imageContainer ${styles.album}`}>{gallery}</div>
      </div>
    </div>
  );
};
